import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 720.000000 552.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,552.000000) scale(0.100000,-0.100000)">
<path d="M0 2760 l0 -2760 3600 0 3600 0 0 2760 0 2760 -3600 0 -3600 0 0
-2760z m3499 2107 c13 -23 58 -98 101 -167 179 -289 350 -570 350 -575 0 -3
-60 -5 -132 -5 l-133 1 -39 67 c-47 81 -177 292 -181 292 -1 0 -55 -87 -121
-194 l-118 -193 -143 -25 c-79 -13 -143 -20 -143 -16 0 5 19 39 41 76 23 37
59 96 81 132 21 36 83 137 137 225 54 88 128 210 165 270 93 152 96 155 104
155 4 0 18 -19 31 -43z m833 -812 c173 -16 354 -41 408 -55 27 -7 20 -7 -25
-3 -100 11 -663 9 -674 -2 -7 -7 3 -30 28 -70 53 -83 241 -393 241 -397 0 -2
-345 -2 -767 0 -423 1 -778 0 -791 -3 -35 -9 -65 -85 -66 -165 -1 -82 33 -156
105 -230 49 -51 188 -146 251 -174 93 -40 -86 11 -247 72 -106 39 -155 65
-246 128 -135 94 -197 180 -206 285 -5 47 -1 67 17 106 91 194 344 332 813
442 302 72 805 100 1159 66z m199 -1291 c140 -41 206 -208 164 -414 -35 -172
-109 -299 -209 -357 -33 -19 -107 -15 -151 7 -35 18 -41 19 -51 5 -13 -18
-154 -22 -154 -5 0 6 14 72 31 147 16 76 46 214 65 308 45 217 39 202 77 208
114 19 117 19 117 2 0 -10 -25 -135 -55 -278 -30 -143 -55 -270 -55 -282 0
-13 7 -26 16 -29 117 -45 229 166 229 434 0 98 -3 120 -20 150 -31 52 -77 72
-152 66 -76 -6 -128 -27 -171 -69 -55 -52 -73 -98 -73 -184 0 -63 -3 -74 -15
-69 -9 3 -22 6 -29 6 -30 0 -55 42 -55 92 0 65 20 108 72 158 105 101 282 144
419 104z m-2575 -7 c9 -9 -6 -89 -84 -457 l-68 -315 -77 -3 c-76 -3 -78 -2
-72 20 3 13 18 88 35 168 16 80 32 155 35 168 5 19 0 25 -27 36 -18 7 -49 16
-69 20 -36 6 -36 6 -62 -51 -31 -70 -81 -228 -96 -301 -6 -29 -11 -82 -11
-117 0 -59 -2 -65 -20 -65 -33 0 -88 27 -99 48 -35 65 5 225 105 425 l34 69
-32 -7 c-77 -17 -82 -22 -78 -77 2 -29 -22 -48 -61 -48 -57 0 -79 75 -35 123
39 42 85 60 169 65 l78 5 37 56 c32 49 150 175 193 206 38 28 97 45 146 42 29
-2 56 -7 59 -10z m495 -56 c17 -18 29 -40 29 -56 0 -35 -50 -85 -85 -85 -35 0
-85 50 -85 85 0 35 50 85 85 85 16 0 38 -12 56 -29z m-263 -33 c-53 -236 -115
-553 -111 -568 8 -29 63 -27 89 3 41 45 55 80 75 175 11 53 27 127 35 165 l16
67 74 0 c42 0 74 -4 74 -10 0 -5 -16 -84 -35 -176 -19 -91 -35 -183 -35 -205
0 -38 1 -39 34 -39 27 0 40 7 60 33 14 17 32 40 41 50 9 10 25 60 36 110 10
51 26 125 35 165 l15 72 75 0 c42 0 74 -4 74 -10 0 -5 -16 -84 -35 -176 -19
-91 -35 -184 -35 -206 0 -38 1 -39 32 -36 43 4 70 53 93 167 9 47 25 125 36
174 l21 87 74 0 c41 0 74 -1 74 -3 0 -15 -86 -427 -96 -459 -15 -50 84 17 104
69 6 16 18 65 26 110 29 161 112 270 222 293 101 21 184 -31 184 -115 0 -17 3
-34 6 -38 18 -18 115 68 148 131 14 27 22 31 92 41 42 6 79 10 80 8 5 -5 24
-274 25 -361 1 -92 -20 -141 -76 -172 -51 -29 -101 -36 -160 -21 -42 10 -53
18 -72 52 -28 50 -29 100 -3 143 19 30 24 33 63 30 41 -3 42 -4 42 -38 0 -31
-4 -36 -32 -45 -36 -10 -41 -25 -17 -49 21 -22 60 -20 84 4 24 24 25 47 8 221
l-11 126 -53 -53 c-29 -29 -69 -58 -88 -65 -28 -10 -36 -18 -36 -39 0 -51 -30
-136 -67 -189 -49 -71 -104 -101 -185 -101 -65 0 -106 16 -136 54 l-19 25 -29
-31 c-16 -17 -48 -39 -71 -50 -31 -14 -44 -26 -49 -46 -23 -103 -38 -139 -75
-174 -43 -41 -86 -54 -152 -45 -57 8 -87 43 -87 102 0 54 35 95 114 131 41 18
56 31 56 45 0 20 0 20 -28 1 -71 -51 -162 -31 -190 40 l-16 39 -24 -31 c-27
-35 -81 -65 -134 -75 -47 -9 -103 17 -120 56 -15 35 -19 35 -44 8 -26 -29 -84
-57 -132 -65 -73 -12 -132 43 -132 124 0 25 116 592 123 597 5 5 142 21 148
18 4 -2 3 -22 -3 -45z m3348 -5 c-10 -35 -26 -108 -26 -125 0 -13 8 -18 26
-18 21 0 25 -4 22 -22 -2 -17 -11 -24 -34 -26 l-31 -3 -32 -154 c-18 -85 -31
-169 -29 -187 3 -29 7 -33 35 -36 39 -4 80 37 89 88 26 164 73 254 163 312 51
32 133 39 167 13 25 -19 34 -19 34 0 0 12 15 15 75 15 41 0 75 -3 75 -7 0 -13
-38 -205 -54 -273 -9 -36 -16 -84 -16 -107 0 -39 2 -42 30 -45 l30 -3 -21 -42
c-25 -50 -61 -66 -114 -52 -25 7 -43 21 -56 44 l-20 34 -37 -35 c-46 -44 -98
-59 -152 -44 -40 11 -90 54 -90 77 0 20 -10 16 -32 -12 -24 -31 -79 -60 -130
-69 -52 -9 -106 15 -121 54 -6 16 -14 30 -17 30 -4 0 -19 -14 -35 -30 -18 -19
-51 -37 -82 -46 -50 -14 -56 -14 -91 3 -20 10 -43 31 -50 46 l-13 28 -37 -36
c-69 -66 -156 -68 -217 -5 -26 27 -30 40 -33 102 -12 207 109 388 259 388 25
0 55 -7 68 -16 22 -16 24 -16 33 0 7 12 24 16 79 16 l71 0 -7 -42 c-4 -24 -20
-104 -36 -179 -36 -160 -36 -212 -1 -217 53 -8 93 62 127 225 14 65 27 128 30
141 4 15 1 22 -10 22 -21 0 -19 39 3 47 24 9 30 20 37 73 9 64 17 73 72 81 90
12 105 11 99 -8z m-2826 -1253 c0 -82 -3 -100 -15 -100 -12 0 -15 17 -15 93 0
52 3 97 7 100 18 19 23 -1 23 -93z m1330 75 c0 -8 -4 -15 -9 -15 -13 0 -22 16
-14 24 11 11 23 6 23 -9z m490 0 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11
11 23 6 23 -9z m-2035 -4 c3 -5 -7 -11 -21 -13 -27 -3 -27 -3 -25 -80 2 -67 0
-78 -14 -78 -14 0 -16 11 -13 78 3 73 2 77 -20 80 -12 2 -22 8 -22 13 0 12
107 12 115 0z m1920 -32 c-4 -6 3 -13 16 -16 23 -6 23 -6 1 -14 -17 -7 -20
-15 -17 -46 2 -20 8 -39 14 -41 21 -7 11 -23 -11 -20 -20 3 -23 9 -24 51 0 26
-3 50 -6 53 -11 10 4 44 19 44 8 0 11 -5 8 -11z m-1795 -24 c21 -25 7 -49 -32
-58 -34 -8 -37 -11 -27 -29 8 -15 17 -18 35 -14 29 8 31 1 5 -14 -43 -23 -76
17 -60 73 14 51 54 72 79 42z m230 0 c20 -24 8 -49 -28 -56 -42 -9 -48 -15
-32 -34 9 -11 22 -14 39 -10 25 6 25 5 9 -11 -14 -15 -22 -16 -43 -6 -24 11
-30 29 -25 77 5 45 54 71 80 40z m130 0 c0 -9 -9 -15 -24 -15 -16 0 -26 -7
-30 -22 -8 -32 -8 -35 10 -52 12 -13 20 -14 37 -4 20 10 21 10 8 -5 -20 -25
-60 -22 -70 5 -9 25 1 71 20 94 15 19 49 18 49 -1z m146 -2 c40 -53 -13 -142
-67 -112 -49 26 -22 129 34 129 11 0 26 -8 33 -17z m102 -60 c-5 -72 -28 -68
-28 5 0 32 3 62 7 66 13 13 24 -24 21 -71z m68 62 c13 -13 18 -13 40 2 22 14
27 14 35 2 12 -19 11 -119 -1 -119 -5 0 -10 20 -10 44 0 50 -10 62 -39 47 -15
-8 -18 -18 -14 -51 4 -31 2 -40 -11 -40 -11 0 -16 13 -18 48 -2 38 -6 47 -20
46 -10 -1 -18 1 -18 4 0 5 31 31 38 32 2 0 10 -7 18 -15z m172 -62 c-5 -72
-28 -68 -28 5 0 32 3 62 7 66 13 13 24 -24 21 -71z m69 65 c4 -7 9 -37 10 -65
2 -40 -1 -53 -12 -53 -10 0 -15 15 -17 48 -2 38 -6 47 -20 46 -10 -1 -18 1
-18 4 0 6 33 31 42 32 4 0 10 -6 15 -12z m77 -10 c16 -78 16 -108 1 -108 -9 0
-15 6 -14 13 6 46 -2 79 -21 84 -19 5 -20 6 -3 19 25 19 32 17 37 -8z m94 -29
c-5 -44 -3 -52 13 -56 10 -3 16 -9 13 -14 -9 -15 -41 -10 -48 7 -8 21 -8 99 1
107 18 18 25 3 21 -44z m68 -77 c-19 -18 -26 75 -10 113 7 15 10 3 12 -44 2
-35 1 -66 -2 -69z m92 51 c-5 -72 -28 -68 -28 5 0 32 3 62 7 66 13 13 24 -24
21 -71z m68 60 c4 -10 9 -39 11 -65 2 -36 -1 -48 -12 -48 -10 0 -15 14 -17 48
-2 38 -6 47 -20 46 -10 -1 -18 1 -18 4 0 6 33 31 41 32 4 0 10 -8 15 -17z
m104 -48 c0 -49 -3 -65 -14 -65 -10 0 -14 17 -15 65 -1 51 2 65 14 65 12 0 15
-14 15 -65z m134 50 c-4 -8 -17 -15 -29 -15 -27 0 -40 -28 -30 -61 5 -16 13
-20 31 -16 13 2 24 1 24 -3 0 -15 -28 -30 -44 -24 -39 15 -41 93 -3 119 28 20
58 20 51 0z m134 3 c7 -7 12 -35 12 -63 l0 -51 -44 -2 c-31 -1 -46 3 -50 13
-10 26 5 44 40 51 43 8 39 27 -7 34 -31 5 -33 6 -14 17 26 16 48 16 63 1z
m222 -53 c0 -51 -3 -65 -15 -65 -12 0 -15 14 -15 65 0 51 3 65 15 65 12 0 15
-14 15 -65z m142 48 c26 -23 23 -69 -7 -98 -28 -29 -34 -30 -59 -12 -22 16
-24 69 -2 101 19 30 42 33 68 9z m102 1 c3 -9 6 -38 6 -65 0 -37 -4 -49 -15
-49 -12 0 -15 14 -15 65 0 62 11 84 24 49z m71 -1 c4 -10 9 -39 11 -65 3 -37
0 -48 -11 -48 -11 0 -15 13 -15 51 0 46 -2 51 -20 46 -25 -6 -26 8 -2 22 24
14 29 14 37 -6z"/>
<path d="M3417 4707 c-24 -39 -88 -144 -142 -232 -53 -88 -121 -198 -151 -245
-29 -46 -51 -86 -50 -88 2 -2 27 0 54 4 l51 6 103 169 c157 258 177 289 185
289 5 -1 53 -75 108 -167 163 -271 149 -253 207 -253 36 0 47 3 42 13 -4 6
-66 109 -139 227 -72 118 -149 243 -170 278 -21 35 -42 65 -46 67 -4 3 -27
-28 -52 -68z"/>
<path d="M3685 3954 c-205 -34 -517 -128 -635 -190 l-45 -24 458 0 c251 0 457
3 457 6 0 16 -134 224 -144 223 -6 -1 -47 -7 -91 -15z"/>
<path d="M1735 2638 c-36 -43 -115 -169 -115 -185 0 -5 27 -15 60 -24 l59 -16
17 76 c9 42 22 102 29 134 17 72 3 76 -50 15z"/>
<path d="M3272 2433 c-52 -52 -103 -233 -85 -303 20 -77 80 -61 126 33 28 58
35 110 22 178 -5 25 -3 40 7 50 9 8 12 24 8 39 -9 36 -44 38 -78 3z"/>
<path d="M2677 1897 c-54 -31 -66 -103 -15 -95 24 3 61 67 56 97 -3 20 -3 20
-41 -2z"/>
<path d="M4952 2427 c-57 -60 -104 -234 -82 -302 9 -28 17 -35 36 -35 36 0 61
16 78 48 20 39 68 271 61 293 -9 28 -66 25 -93 -4z"/>
<path d="M5797 2422 c-56 -58 -103 -248 -74 -302 23 -46 83 -35 112 19 19 37
66 276 59 296 -10 26 -67 19 -97 -13z"/>
<path d="M2560 1401 c-10 -19 -8 -21 20 -21 25 0 31 4 28 17 -5 26 -35 28 -48
4z"/>
<path d="M2791 1406 c-18 -21 -6 -34 24 -28 17 3 25 10 23 21 -4 21 -32 25
-47 7z"/>
<path d="M3079 1413 c-22 -5 -30 -38 -19 -69 17 -43 60 -23 60 27 0 30 -17 47
-41 42z"/>
<path d="M4250 1340 c0 -16 4 -18 22 -11 32 12 34 31 4 31 -19 0 -26 -5 -26
-20z"/>
<path d="M4623 1412 c-15 -10 -25 -46 -17 -65 16 -42 64 -24 64 24 0 29 -27
53 -47 41z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
